a {
  color: rgb(0, 162, 255);
}

h2 {
  color: rgba(255, 255, 255, 0.863);
}

p {
  color: rgb(202, 202, 202);
}

.blog-img {
  display: block;
  margin: 2vw auto;
  max-width: 90%;
  height: auto;
}

.fade-appear {
  opacity: 0;
  z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 750ms linear;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 750ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 750ms linear;
}

.fade-exit-done {
  opacity: 0;
}
